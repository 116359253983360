import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import AuthLayout from '../layouts/AuthLayout.vue'
import AppLayout from '../layouts/AppLayout.vue'

import RouteViewComponent from '../layouts/RouterBypass.vue'
import { useUserStore } from '../stores/user-store'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: '404' },
  },
  {
    name: 'landing',
    path: '/',
    component: () => import('../pages/LandingPage.vue'),
  },
  {
    name: 'admin',
    path: '/dashboard',
    component: AppLayout,
    redirect: { name: 'dashboard' },
    children: [
      {
        name: 'dashboard',
        path: 'dashboard',
        component: () => import('../pages/admin/dashboard/Dashboard.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: 'settings',
        path: 'settings',
        component: () => import('../pages/settings/Settings.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: 'preferences',
        path: 'preferences',
        component: () => import('../pages/preferences/Preferences.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: 'users',
        path: 'users',
        component: () => import('../pages/users/UsersPage.vue'),
        meta: { requiresAuth: true, roles: ['Administrador', 'Proprietário'] },
      },
      {
        name: 'projects',
        path: 'projects',
        component: () => import('../pages/projects/ProjectsPage.vue'),
        meta: { requiresAuth: true, roles: ['Administrador', 'Proprietário'] },
      },
      {
        name: 'payments',
        path: '/payments',
        component: RouteViewComponent,
        children: [
          {
            name: 'payment-methods',
            path: 'payment-methods',
            component: () => import('../pages/payments/PaymentsPage.vue'),
            meta: { requiresAuth: true, roles: ['Administrador', 'Proprietário'] },
          },
          {
            name: 'billing',
            path: 'billing',
            component: () => import('../pages/billing/BillingPage.vue'),
            meta: { requiresAuth: true, roles: ['Administrador', 'Proprietário'] },
          },
          {
            name: 'pricing-plans',
            path: 'pricing-plans',
            component: () => import('../pages/pricing-plans/PricingPlans.vue'),
          },
        ],
      },
      {
        path: '/pagamento_mp',
        component: RouteViewComponent,
        children: [
          {
            name: 'pagamento-success',
            path: 'success',
            component: () => import('../pages/pagamento_mp/Success.vue'),
            props: (route) => ({ query: route.query }),
          },
          {
            name: 'pagamento-failure',
            path: 'failure',
            component: () => import('../pages/pagamento_mp/Failure.vue'),
            props: (route) => ({ query: route.query }),
          },
          {
            name: 'pagamento-pending',
            path: 'pending',
            component: () => import('../pages/pagamento_mp/Pending.vue'),
            props: (route) => ({ query: route.query }),
          },
        ],
      },
      {
        name: 'faq',
        path: '/faq',
        component: () => import('../pages/faq/FaqPage.vue'),
      },
    ],
  },
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'login',
        path: 'login',
        component: () => import('../pages/auth/Login.vue'),
      },
      {
        name: 'signup',
        path: 'signup',
        component: () => import('../pages/auth/Signup.vue'),
      },
      {
        name: 'recover-password',
        path: 'recover-password',
        component: () => import('../pages/auth/RecoverPassword.vue'),
      },
      {
        name: 'recover-password-email',
        path: 'recover-password-email',
        component: () => import('../pages/auth/CheckTheEmail.vue'),
      },
      {
        name: 'reset-password',
        path: 'reset-password/:uid/:token',
        component: () => import('../pages/auth/ResetPassword.vue'),
      },
      {
        path: '',
        redirect: { name: 'login' },
      },
    ],
  },
  {
    name: '404',
    path: '/404',
    component: () => import('../pages/404.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    // For some reason using documentation example doesn't scroll on page navigation.
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes,
})

// Adicionar guarda de rota para verificar autenticação
router.beforeEach(async (to, from, next) => {
  const userStore = useUserStore()
  await userStore.checkAuth()
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!userStore.isLoggedIn) {
      next({ name: 'login' })
    } else {
      const requiredRoles = to.meta.roles as string[] | undefined
      if (requiredRoles && !userStore.hasRole(requiredRoles)) {
        next({ name: 'dashboard' })
      } else {
        next()
      }
    }
  } else if (to.name === 'landing' && userStore.isLoggedIn) {
    next({ name: 'dashboard' })
  } else {
    next()
  }
})

export default router
