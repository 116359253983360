<template>
  <RouterView />
  <!-- <div class="whatsapp-float" @click="openWhatsApp">
    <i class="fab fa-whatsapp"></i>
  </div> -->
</template>

<script lang="ts" setup>
import { onMounted } from 'vue'
import { useColors } from 'vuestic-ui'

const { applyPreset } = useColors()

// Restaurar o tema selecionado do localStorage ao montar o aplicativo
onMounted(() => {
  const savedTheme = localStorage.getItem('theme')
  if (savedTheme) {
    applyPreset(savedTheme)
  }
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const openWhatsApp = () => {
  const phoneNumber = '+5511999999999'
  const whatsappUrl = `https://wa.me/${phoneNumber}`
  window.open(whatsappUrl, '_blank')
}
</script>

<style lang="scss">
@import 'scss/main.scss';

#app {
  font-family: 'Inter', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  min-width: 20rem;
}

.whatsapp-float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>
