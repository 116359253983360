<template>
  <div class="logo-container">
    <img
      :src="logoType === 'horizontal' ? logoUrlHorizontal : logoUrl"
      :alt="altText"
      :height="height"
      :width="width"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useColors } from 'vuestic-ui'
import logoUrl from '@/components/logo.svg'
import logoUrlHorizontal from '@/components/logo_2.svg' // Importação do novo logo

const { getColor } = useColors()

const props = withDefaults(
  defineProps<{
    height?: number
    width?: number
    start?: string
    end?: string
    altText?: string
    logoType?: string // Nova propriedade para escolher o tipo de logo
  }>(),
  {
    height: 500,
    width: 500,
    start: 'primary',
    end: undefined,
    altText: 'Fly Hunt',
    logoType: 'vertical', // Valor padrão
  },
)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const colorsComputed = computed(() => {
  return {
    start: getColor(props.start),
    end: getColor(props.end || props.start),
  }
})
</script>
